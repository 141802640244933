// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

export const environment = {
  production: false,
};

const firebaseConfig = {
  apiKey: 'AIzaSyBYRyt-dVEjSl5faurfbFtIlh3nNku6-hE',
  authDomain: 'milanesas-e917d.firebaseapp.com',
  projectId: 'milanesas-e917d',
  storageBucket: 'milanesas-e917d.appspot.com',
  messagingSenderId: '255790868360',
  appId: '1:255790868360:web:4f6cab5ad06c77cf4b7c3d',
  measurementId: 'G-QEKEB9TS58',
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
