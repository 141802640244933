<div class="main-container">
  <img src="/assets/images/placeholders/paper.jpg" alt="" />
  <div class="middle">
    <h1>¿Quién eres tu?</h1>
    <div class="form">
      <div class="row">
        <input type="text" placeholder="Nombre(s)" />
        <input type="text" placeholder="Apellidos" />
        <select>
          <option value="">Edad</option>
        </select>
      </div>
      <div class="row_two">
        <input type="text" placeholder="Celular" />
        <p>¿Es estudiante?</p>
        <div class="input_container">
          <div
            class="circle"
            [class.active]="this.index == 1"
            (click)="index = 1"
          ></div>
          <p>Si</p>
        </div>
        <div class="input_container">
          <div
            class="circle"
            [class.active]="this.index == 0"
            (click)="index = 0"
          ></div>
          <p>No</p>
        </div>
      </div>
      <div class="student-container" *ngIf="index == 1">
        <div class="input-container">
          <label>¿En donde estudia?</label>
          <input type="text" placeholder="" />
        </div>
        <div class="row">
          <div class="input-container" style="width: 100%">
            <label>¿En donde estudia?</label>
            <input type="text" placeholder="" />
          </div>
          <div class="input-container">
            <label>¿En donde estudia?</label>
            <select>
              <option value="">Hora Salida</option>
            </select>
          </div>
          <select>
            <option value="">Hora Salida</option>
          </select>
        </div>
      </div>
      <div class="last">
        <h2>Dirección</h2>
        <div class="row">
          <input type="text" placeholder="Calle 1" />
          <input type="text" placeholder="Calle 2 (Opcional)" />
          <select>
            <option value="">Localidad</option>
          </select>
        </div>
      </div>
    </div>
    <button>ENVIAR</button>
  </div>
  <img src="/assets/images/placeholders/paper.jpg" alt="" />
</div>
