<div class="main-color">
  <div class="main-container">
    <img class="line-map" src="assets/images/placeholders/line-history.png" alt="">
    <div class="first-section">
      <div class="row">
        <h1>
          ¡ASÍ COMIENZA <br />
          <span>LA BÚSQUEDA DEL TESORO!</span>
          <img
            class="first-point"
            src="/assets/images/icons/redcross.png"
            alt=""
          />
          <!-- <img class="main-line" src="/assets/images/placeholders/line-history.png" alt=""> -->
        </h1>
        <img
          class="right-side"
          src="/assets/images/placeholders/right-history1.png"
          alt=""
        />
      </div>
    </div>
    <div class="second-section">
      <div class="row">
        <h2>
          ORIGEN
          <img class="second-point" src="/assets/images/icons/cross.png" alt="" />
        </h2>
        <div class="par-second-section">
          <p>
            Milanesas del Pirata® surge de una receta muy especial y llena de
            tradición familiar, una receta que ha pasado de generación en
            generación por más de 60 años y que gracias a nuestra abuela, a la que
            apodamos con cariño "La Abuela Pirata", pudimos obtener y darle ese 
            "crunchy" especial a nuestras milanesas.
          </p>
        </div>
      </div>
    </div>
    <div class="third-section">
      <div class="row">
        <img class="third-point" src="/assets/images/icons/redcross.png" alt="" />
        <h1>
          PRIMERA SUCURSAL
          <p>
            Fue inaugurada el 6 de septiembre del 2018 donde en esta misma fecha
            de obtiene el registro de marca en el IMPI.
            <br />
            <br />
            En la actualidad contamos con una segunda sucursal en la Ciudad de
            Querétaro (Plaza Uptown Juriquilla).
          </p>
        </h1>
      </div>
    </div>
    <div class="fourth-section">
      <div class="row">
        <h2>
          COMO EMPRESA
          <img
            class="fourth-point"
            src="/assets/images/icons/cross2.png"
            alt=""
          />
        </h2>
        <div class="par-fourth-section">
          <p>
            La marca de milanesas del pirata es una marca joven pero sólida, donde
            apostamos todo por mantener la calidad de nuestras recetas y así
            consentir a nuestros consumidores.
          </p>
        </div>
        <div class="par2-fourth-section">
          <img class="chile-paper" src="assets/images/placeholders/chilli-paper.png" alt="">
          <p>
            Nos apoyamos en tecnologías de punta para 
            poder mantener los mejores controles 
            administrativos y operativos, además 
            queremos ser una opción para los 
            emprendedores que quieran entrar en este 
            bello mundo de la gastronomía. 
            <br><br>
            En Milanesas del Pirata® estamos trabajando 
            en un innovador y brillante modelo de 
            DELIVERY para el 2023.
          </p>
        </div>
      </div>
    </div>
    <div class="fifth-section">
      <div class="row">
        <h3 class="first-msg">
          Nuestras ganas de transmitir a las nuevas 
          generaciones esta magnifica receta nos 
          llevaron a iniciar este negocio.
        </h3>
      </div>
      <div class="row1">
        <h1>
          <img class="fifth-point" src="/assets/images/icons/redcross.png" alt=""/>
          EN AÑOS RECIENTES
        </h1>
        <p>
          2019
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Se explota un nuevo territorio en Centro Comercial Up Town LcL-47 
          Anillo vial Fray Junipero Serra #21 260 Colonia el Salitre, Epigmenio 
          González, 761 27 Juriquilla, Qro. 
        </p>
        <p>
          2021
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Se abre una de las sucursales modelo en Blvd. Quiroga No. 74, local 4, Col. 
          Conquistadores. Hermosillo, Sonora.
        </p>
        <p>
          2023
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Además, seguiremos sorprendiendo a nuestros clientes con nuestro 
          sabor y rapidez en una 3ra sucursal para aperturar en el 2023.
        </p>
      </div>
    </div>
  </div>
</div>

<!-- comment -->
<!-- <div class="row">
      <p>
        Nuestras ganas de transmitir a las nuevas generaciones esta magnifica
        receta nos llevaron a iniciar este negocio al que solo le faltaba el
        nombre; el cual nos vino a la mente al bromear constantemente sobre "las
        milanesas de la abuela pirata", un nombre con el que finalmente nos
        daríamos a conocer no solo en Sonora sino en todo México y el mundo...
        Milanesas del Pirata®
      </p>
    </div>
    <div class="row">
      <p>
        Nuestra primera sucursal fue inaugurada el 6 de septiembre del 2018 y en
        la actualidad contamos con una segunda sucursal en la Ciudad de
        Querétaro (Plaza Uptown Juriquilla). Además, seguiremos sorprendiendo a
        nuestros clientes con nuestro sabor y rápidez en una 3ra sucursal para
        aperturar en el 2023.
      </p>
    </div>
    <div class="row">
      <p>
        La marca de milanesas del pirata es una marca joven pero sólida, donde
        apostamos todo por mantener la calidad de nuestras recetas y así
        consentir a nuestros consumidores. Nos apoyamos en tecnologías de punta
        para poder mantener los mejores controles administrativos y operativos,
        además queremos ser una opción para los emprendedores que quieran entrar
        en este bello mundo de la gastronomía. En Milanesas del Pirata® estamos
        trabajando en un innovador y brillante modelo de DELIVERY para el 2023.
      </p>
    </div>
    <div class="row">
      <p>
        2018: Milanesas del Pirata: inició operaciones el 6 de septiembre de
        2018 en la Ciudad de Hermosillo, Sonora, donde en esta misma fecha de
        obtiene el registro de marca en el IMPI. 2019: Se explota un nuevo
        territorio en Centro Comercial Up Town LcL-47 Anillo vial Fray Junipero
        Serra #21 260 Colonia el Salitre, Epigmenio González, 761 27 Juriquilla,
        Qro. 2021: Se abre una de las sucursales modelo en Blvd. Quiroga No. 74,
        local 4, Col. Conquistadores. Hermosillo, Sonora.
      </p>
    </div> -->
