import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss'],
})
export class BranchesComponent implements OnInit {
  locations = [
    {
      name: 'Milanesas del Pirata',
      location:
        'Blvd. Antonio Quiroga #74, Plaza Conquistador, 83224 Hermosillo, Son.',
      phone: 5216622339073,
      selected: true,
      lat: 29.09154687048637,
      long: -111.02259169999999,
    },
    {
      name: 'Milanesas del Pirata',
      location:
        'Anillo Vial Fray Junípero Serra #21260, CP: 76223, Santiago de Querétaro, Querétaro',
      phone: 5214422056178,
      selected: false,
      lat: 20.676588140776254,
      long: -100.43144691534222,
    },
  ];
  selected_location: any = {};
  lat = 0;
  long = 0;
  constructor() {}

  ngOnInit(): void {
    this.selected_location = this.locations[0];
    this.lat = this.locations[0].lat;
    this.long = this.locations[0].long;
  }

  toggleLocation(index) {
    for (let location of this.locations) {
      if (location.selected) location.selected = false;
    }
    this.locations[index].selected = true;
    this.selected_location = this.locations[index];
    this.lat = this.locations[index].lat;
    this.long = this.locations[index].long;
  }
}
