import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { NavbarComponent } from './share/navbar/navbar.component';
import { FooterComponent } from './share/footer/footer.component';
import { MenuComponent } from './pages/menu/menu.component';
import { AgmCoreModule } from '@agm/core';
import { FranchisesComponent } from './pages/franchises/franchises.component';
import { BranchesComponent } from './pages/branches/branches.component';
import { HistoryComponent } from './pages/history/history.component';

import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobComponent } from './pages/job/job.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PolicyComponent } from './pages/policy/policy.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NavbarComponent,
    FooterComponent,
    MenuComponent,
    FranchisesComponent,
    BranchesComponent,
    HistoryComponent,
    JobComponent,
    ContactComponent,
    PolicyComponent,
    ],
  imports: [
    SwiperModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAyFprmQnSvjNIctZt_EkgM6CDLgmzLPxk',
      libraries: ['places'],
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
