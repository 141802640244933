<div class="footer">
  <img
    class="logo"
    src="../../../assets/images/logos/milanesas_new_logo.png"
    alt=""
  />
  <div class="right">
    <div class="left-info">
      <p class="bold"
        style="
          text-decoration: underline;
          cursor: pointer;
        " 
        routerLink="/Aviso-de-privacidad"
      >Aviso de privacidad</p>
      <p>Milanesas del pirata © 2022</p>
    </div>
    <div class="middle-info">
      <p class="bold">Cóntacto:</p>
      <a class="phone-info" href="tel:+52-662-213-1777">+52 (662) 213 17 77</a>
      <a class="email-info" href="mailto:franquicias@milanesasdelpirata.com.mx">
        franquicias@milanesasdelpirata.com.mx
      </a>
      <a class="email-info" href="mailto:contacto@milanesasdelpirata.com.mx">
        contacto@milanesasdelpirata.com.mx
      </a>
    </div>
    <div class="right-info">
      <p class="bold">Redes Sociales</p>
      <div class="images">
        <a href="https://www.facebook.com/milanesasdelpirataconquistador" target="_blank">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com/milanesasdelpirata/" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://www.twitter.com/Piratafranchise" target="_blank">
          <i class="fab fa-twitter"></i>
        </a>
      </div>
    </div>
  </div>
</div>
