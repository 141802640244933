<div class="main-container">
  <div class="second-section">
    <div class="map">
      <agm-map [latitude]="lat" [longitude]="long" [zoom]="16">
        <agm-marker [latitude]="lat" [longitude]="long"> </agm-marker>
      </agm-map>
    </div>
    <div class="gradient"></div>
    <div class="title-container">
      <h4>UBICA TU SUCURSAL...</h4>
      <h6>LA X MARCA EL LUGAR</h6>
    </div>
    <div class="map-section">
      <div class="map-locations">
        <div
          (click)="toggleLocation(i)"
          class="location-info"
          [ngStyle]=
          "{ opacity: location.selected ? 1 : 0.5 
          }"
          
          *ngFor="let location of locations; let i = index"
        >
          <img
            class="parchment"
            src="/assets/images/placeholders/parchment1.png"
            alt=""
          />
          <div class="left">
            <p class="title">{{ location.name }}</p>
            <p>{{ location.location }}</p>
            <div class="whatsapp">
              <a href="https://api.whatsapp.com/send/?phone={{location.phone}}" target="_blank">
                <button class="makeOrder">Ordena Aquí <i class="fab fa-whatsapp"></i></button>
              </a>
            </div>
          </div>
          <img
            class="marker"
            width="20"
            src="/assets/images/icons/brown-marker.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
