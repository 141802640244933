<div class="main-container">
  <div class="first-section">
    <video
      id="video"
      class="video-js vjs-16-9"
      data-setup="{}"
      autoplay
      muted
      loop
      playsinline
    ></video>
  </div>
  <div class="section">
    <p>
      Somos la única franquicia en México especializada en las mejores MILANESAS
      con receta del noroeste del país, con ese delicioso e incomparable
      empanizado que encantará a todo México, latinoamérica y el resto del
      mundo.
    </p>
    <img src="/assets/images/logos/milanesas_new_logo.png" alt="" />
  </div>
  <!-- <div class="second-section">
    <div class="map">
      <agm-map [latitude]="lat" [longitude]="long" [zoom]="16">
        <agm-marker [latitude]="lat" [longitude]="long"> </agm-marker>
      </agm-map>
    </div>
    <div class="gradient"></div>
    <div class="title-container">
      <h4>UBICA TU SUCURSAL...</h4>
      <h6>LA X MARCA EL LUGAR</h6>
    </div>
    <div class="map-section">
      <div class="map-locations">
        <div
          (click)="toggleLocation(i)"
          class="location-info"
          [ngStyle]="{
            opacity: location.selected ? 1 : 0.5,
            'left.px': location.selected ? 1 : -150
          }"
          *ngFor="let location of locations; let i = index"
        >
          <img
            class="parchment"
            src="/assets/images/placeholders/parchment1.png"
            alt=""
          />
          <div class="left">
            <p class="title">{{ location.name }}</p>
            <p>{{ location.location }}</p>
          </div>
          <img
            class="marker"
            width="20"
            src="/assets/images/icons/brown-marker.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div> -->
  <div class="fifth-section">
    <div class="title-container">
      <h4>UNA EXPERIENCIA DE SABOR</h4>
      <h6>#SIMPLEMENTE UN TESORO</h6>
    </div>
    <div class="plates-container">
      <div class="plate">
        <img src="../../../assets/images/plates/first-food.png" alt="" />
        <div class="text">Charola pirata</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/second-food.png" alt="" />
        <div class="text">Hamburguesa</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/third-food.png" alt="" />
        <div class="text">Mar y tierra</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/fourth-food.png" alt="" />
        <div class="text">Completa mixta</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/fifth-food.png" alt="" />
        <div class="text">Boneless</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/sixth-food.png" alt="" />
        <div class="text">Media milanesa</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/seventh-food.png" alt="" />
        <div class="text">Papas con queso</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/eigth-food.png" alt="" />
        <div class="text">Camarones piratas</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/nineth-food.png" alt="" />
        <div class="text">Charola pirata</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/tenth-food.png" alt="" />
        <div class="text">Mar y tierra</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/eleventh-food.png" alt="" />
        <div class="text">Cielo mar y tierra</div>
      </div>
      <div class="plate">
        <img src="../../../assets/images/plates/twelfth-food.png" alt="" />
        <div class="text">Completa de res</div>
      </div>
    </div>
  </div>
  <div class="third-section">
    <div class="red-triangle">
      <img src="/assets/images/placeholders/RightTriangle.png" alt="">
    </div>
    <div class="red-box">
      <div class="title-container">
        <h4>NUESTRAS PROMOCIONES</h4>
        <h6>SIMPLEMENTE UN TESORO</h6>
      </div>
      <div class="promos-container">
        <div *ngFor="let promo of slicePromos" class="promo">
          <img src="{{promo.img}}" alt="" />
        </div>
      </div>
      <!-- <button *ngIf="slicePromos.length == 2" (click)="showMore()">
        VER TODAS LAS PROMOCIONES
      </button> -->
    </div>
    <div class="red-triangle2">
      <img src="/assets/images/placeholders/LeftTriangle.png" alt="">
    </div>
  </div>
  <div class="fourth-section">
    <div class="title-container">
      <h4>FRANQUICIAS</h4>
      <h6>UNETE A NUESTRO EQUIPO</h6>
    </div>
    <img
      routerLink="/Franquicia"
      class="bg"
      src="../../../assets/images/placeholders/sucursal_new_bg.png"
      alt=""
    />
    <div class="red-container" routerLink="/Franquicia">
      <h2>Únete a nuestra red de Franquicias</h2>
      <h5>"Franquicias disponibles"</h5>
      <h5>+52(662) 2 13 17 17</h5>
      <p>www.milanesasdelpirata.com.mx</p>
      <p>franquicias@milanesasdelpirata.com.mx</p>
    </div>
    <div class="dark-container" routerLink="/Franquicia">
      <h3>#UNTESORODEINVERSION</h3>
      <!-- <h4><img src="../../../assets/images/icons/wa.png" /> 662 329 0233</h4> -->
    </div>
  </div>
  <div class="last-section">
    <div class="forma" id="landing">
      <h5>CONTACTANOS</h5>
      <div class="row">
        <div class="input-container">
          <label for="">Nombre(s)</label>
          <input type="text" [(ngModel)]="landingForm.name" />
        </div>
        <div class="input-container">
          <label for="">Apellidos</label>
          <input type="text" [(ngModel)]="landingForm.lastName" />
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <label for="">Correo</label>
          <input type="text" [(ngModel)]="landingForm.email" />
        </div>
        <div class="input-container">
          <label for="">Celular</label>
          <input type="number" [(ngModel)]="landingForm.phoneNumber" />
        </div>
        <div class="input-container">
          <label for="">Edad</label>
          <input type="number" [(ngModel)]="landingForm.age" />
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <label for="">¿Cómo se entero de nosotros?</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            [(ngModel)]="landingForm.about"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <label for="">!Dejenos sus comentarios!</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            [(ngModel)]="landingForm.comment"
          ></textarea>
        </div>
      </div>
      <div class="row custom-row">
        <input
          class="last-section-agreement"
          type="checkbox"
          id="Agreement"
          name="Agreement"
          value=""
          (click)="agreementYes()"
        />
        <label for="Agreement"> </label>
        <p>
          Al marcar la casilla y hacer clic en el botón "Pedir información",
          confirmo que he leído y acepto los
          <span
            style="
              display: inline;
              color: #00a3ff;
              text-decoration: underline;
              cursor: pointer;
            "
            routerLink="/Aviso-de-privacidad"
            >Términos de servicio</span
          >
          y la
          <span
            style="
              display: inline;
              color: #00a3ff;
              text-decoration: underline;
              cursor: pointer;
            "
            routerLink="/Aviso-de-privacidad"
            >Política de privacidad</span
          >.
        </p>
      </div>
      <button (click)="submit()" *ngIf="activeButton" [disabled]="isLoading">
        PEDIR INFORMACIÓN
      </button>
    </div>
  </div>
</div>
