import { Component, OnInit } from '@angular/core';
import { db, functions } from 'src/environments/environment';
import { httpsCallable } from 'firebase/functions';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-franchises',
  templateUrl: './franchises.component.html',
  styleUrls: ['./franchises.component.scss'],
})
export class FranchisesComponent implements OnInit {
  isLoading: boolean = false;
  config = {
    loop: true,
    autoplay: {
      delay: 0,
    },
    speed: 4000,
    freeMode: true,
  };
  activeButton: boolean = false;
  agreementYes() {
    this.activeButton = !this.activeButton;
  }
  franchiseForm: any = {
    origin: 'Franquicias',
    name: '',
    lastName: '',
    age: '',
    email: '',
    phoneNumber: '',
    job: '',
    experience: '',
    city: '',
    monthyear: '',
    ownername: '',
    availability: '',
    state: '',
    about: '',
    comment: '',
  };
  constructor() {}

  ngOnInit(): void {}

  goTo(el: string) {
    document.getElementById(el).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
  scrollToElement($element): void {
    console.log($element);
    setTimeout(function () {
      document.getElementById($element).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 100);
  }
  async submit() {
    this.franchiseForm.button = this.activeButton;
    if (
      this.franchiseForm.name !== '' &&
      this.franchiseForm.lastName !== '' &&
      this.franchiseForm.email !== '' &&
      this.franchiseForm.phoneNumber !== '' &&
      this.franchiseForm.age !== '' &&
      this.franchiseForm.experience !== '' &&
      this.franchiseForm.city !== '' &&
      this.franchiseForm.monthyear !== '' &&
      this.franchiseForm.ownername !== '' &&
      this.franchiseForm.availability !== '' &&
      this.franchiseForm.state !== '' &&
      this.franchiseForm.about !== '' &&
      this.franchiseForm.comment !== ''
    ) {
      if (this.isLoading == false) {
        alert('¡Su correo fue enviado con exito!');
        const send = httpsCallable(functions, 'sendEmailContact');
        await send(this.franchiseForm).then((res) => {
          console.log(res.data);
          this.isLoading = true;
        });
      }
    } else {
      alert('¡Por favor complete los campos!');
    }
    this.franchiseForm = '';
  }
}
