<div class="main-color">
  <div class="main-container" id="franchises">
    <img
      class="first-container"
      src="/assets/images/placeholders/bg-franchises1.png"
      alt=""
    />
    <div class="second-container">
      <h1 class="second-container-title">
        CONCEPTO<br />
        <span>DE FRANQUICIA</span>
      </h1>
      <p class="second-container-par">
        “Somos un Fast-Food Restaurant especializado en la elaboración de
        milanesas, hamburguesas, boneless y otros complementos.
        <br />
        Buscamos siempre la innovación en la creación de recetas propias,
        brindando a nuestros clientes productos inocuos y de calidad para
        satisfacer el paladar de niños y adultos.”
      </p>
    </div>
    <div class="Third-container">
      <div class="third-container-parchment">
        <img class="parchment-img"
          src="assets/images/placeholders/parchment1.png"
          alt=""
        />
        <div class="third-container-paragraph">
          <p class="paragraph-img">
            NUESTROS
            <br>
            <span>
            MODELOS
            <br>
            DE FRANQUICIA
            </span>
          </p>
          <div class="hats-promos">
            <div class="promo-1">
              <img class="hat-img" src="assets/images/placeholders/Hat.png" alt="">
              <div class="promo-inf">
                <div class="first-word">
                  MODELO
                </div>
                <div class="second-word">
                  ESTANDAR
                </div>
                <div class="promo-scale">
                  50 a 60 m<sup>2</sup>
                </div>
              </div>
            </div>
            <div class="promo-2">
              <img class="hat-img" src="assets/images/placeholders/Hat.png" alt="">
              <div class="promo-inf">
                <div class="first-word">
                  MODELO
                </div>
                <div class="second-word">
                  FAST
                </div>
                <div class="promo-scale">
                  50 a 60 m<sup>2</sup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="third-container-parchment-2">
        <img class="parchment-img"
          src="assets/images/placeholders/parchment1.png"
          alt=""
        />
        <div class="parchment-photo">
          <img class="photo" 
            src="assets/images/placeholders/parchment-photo.png" 
            alt=""
          />
        </div>
      </div>
      <div class="third-container-paragraph">
        <p class="paragraph-info">
          <span>¡Emprende tu franquicia y descubre tu tesoro de inversión!</span>
          Únete a nuestra red de franquicias
          <br>
          “Franquicias Disponibles”
        </p>
        <p class="paragraph-contact" routerLink="" (click)="scrollToElement('landing')">
          +52 1 662 427 4367 | franquicias@milanesasdelpirata.com.mx
        </p>
      </div>
    </div>
    <div class="Fourth-container">
      <h5 class="Fourth-container-title">
        BENEFICIOS DE NUESTRA RED DE FRANQUICIAS
      </h5>
      <div class="Fourth-container-body">
        <div class="Fourth-container-column">
          <div class="Fourth-container-title1">
            <img
              class="Fourth-container-icon1"
              src="/assets/images/icons/capacitacion1.png"
              alt=""
            />
            <h2 class="Fourth-container-subtitle1">Capacitación</h2>
          </div>
          <ul>
            <li>Contamos con un centro de capacitación a Franquiciatarios.</li>
            <li>Capacitación Inicial para el arranque de la franquicia.</li>
            <li>
              Capacitación de Mejora Continua en procesos y atención al cliente.
            </li>
            <li>
              Capacitación de Bienestar Laboral para la red de Franquiciatarios.
            </li>
            <li>Manuales de operación, imagen y capacitación.</li>
            <li>
              Guía de como supervisar y dar asistencia técnica continua a tu
              propia franquicia.
            </li>
          </ul>
        </div>
        <div class="Fourth-container-column">
          <div class="Fourth-container-title1">
            <img
              class="Fourth-container-icon2"
              src="/assets/images/icons/corona1.png"
              alt=""
            />
            <h2 class="Fourth-container-subtitle1">Exclusividad</h2>
          </div>
          <ul>
            <li>Exclusividad de territorio por cada franquicia.</li>
            <li>Licencia de uso de marca exclusiva.</li>
            <li>Suministro exclusivo de productos.</li>
            <li>
              Uso y soporte exclusivo del Sistema para la administración de tu
              franquicia.
            </li>
          </ul>
          <div class="Fourth-container-title1">
            <img
              class="Fourth-container-icon2"
              src="/assets/images/icons/apoyo-tecnico1.png"
              alt=""
            />
            <h2 class="Fourth-container-subtitle1">Asistencia</h2>
          </div>
          <ul>
            <li>
              Asesoría y gestión en publicidad y marketing offline y online.
            </li>
            <li>
              Asistencia Técnica 24/7 respecto a la operación de tu franquicia.
            </li>
          </ul>
        </div>
        <div class="Fourth-container-column">
          <div class="Fourth-container-title1">
            <img
              class="Fourth-container-icon3"
              src="/assets/images/icons/incentivo1.png"
              alt=""
            />
            <h2 class="Fourth-container-subtitle1-last-container">Mas beneficios</h2>
          </div>
          <ul class="last-container">
            <li>
              Acompañamiento para la selección de ubicación de tu local para tu
              franquicia.
            </li>
            <li>
              Innovación constante en productos con base a tendencia de mercado.
            </li>
            <li>Tipos de franquicias a otorgar: Unitarias y/o Regionales.</li>
            <li>Esquema de franquicia a otorgar: Nueva.</li>
            <li>
              Franquicias Llave en mano (Nosotros montamos la franquicia por ti)
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="last-section">
      <div class="forma" id="contact">
        <h5>CONTACTANOS</h5>
        <div class="row">
          <div class="input-container">
            <label for="">Nombre(s)</label>
            <input type="text" [(ngModel)]="franchiseForm.name" />
          </div>
          <div class="input-container">
            <label for="">Apellidos</label>
            <input type="text" [(ngModel)]="franchiseForm.lastName" />
          </div>
        </div>
        <div class="row">
          <div class="input-container">
            <label for="">Correo</label>
            <input type="text" [(ngModel)]="franchiseForm.email" />
          </div>
          <div class="input-container">
            <label for="">Celular</label>
            <input type="number" [(ngModel)]="franchiseForm.phoneNumber" />
          </div>
          <div class="input-container">
            <label for="">Edad</label>
            <input type="number" [(ngModel)]="franchiseForm.age" />
          </div>
        </div>
        <div class="row">
          <div class="input-container">
            <label for="">Tiene experiencias en franquicias?</label>
            <select required [(ngModel)]="franchiseForm.experience">
              <option value="False">No</option>
              <option value="true">Si</option>
            </select>
          </div>
          <div class="input-container">
            <label for="">Estado de Interés</label>
            <input type="text" [(ngModel)]="franchiseForm.state" />
          </div>
          <div class="input-container">
            <label for="">Ciudad de interés</label>
            <input type="text" [(ngModel)]="franchiseForm.city" />
          </div>
        </div>
        <div class="row">
          <div class="input-container">
            <label for="">Mes/año tiene pensado invertir</label>
            <input type="month" [(ngModel)]="franchiseForm.monthyear" />
          </div>
          <div class="input-container">
            <label for="">Quien operaría la franquicia</label>
            <input type="text" [(ngModel)]="franchiseForm.ownername">
          </div>
          <div class="input-container">
            <label for="">Cual es su disponibilidad para operar</label>
            <select required [(ngModel)]="franchiseForm.availability">
              <option value="fullTime">Tiempo Completo</option>
              <option value="partTime">Medio Tiempo</option>
              <option value="casual">Muy Poco</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="input-container">
            <label for="">¿Cómo se entero de nosotros?</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              [(ngModel)]="franchiseForm.about"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="input-container">
            <label for="">!Dejenos sus comentarios!</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              [(ngModel)]="franchiseForm.comment"
            ></textarea>
          </div>
        </div>
        <div class="row custom-row">
          <input
            class="last-section-agreement"
            type="checkbox"
            id="Agreement"
            name="Agreement"
            value=""
            (click)="agreementYes()"
          />
          <label for="Agreement"> </label>
          <p>
            Al marcar la casilla y hacer clic en el botón "Pedir información",
            confirmo que he leído y acepto los
            <span
              style="
                display: inline;
                color: #00a3ff;
                text-decoration: underline;
                cursor: pointer;
              "
              routerLink="/Aviso-de-privacidad"
              >Términos de servicio</span
            >
            y la
            <span
              style="
                display: inline;
                color: #00a3ff;
                text-decoration: underline;
                cursor: pointer;
              "
              routerLink="/Aviso-de-privacidad"
              >Política de privacidad</span
            >.
          </p>
        </div>
        <button (click)="submit()" *ngIf="activeButton" [disabled]="isLoading">
          PEDIR INFORMACIÓN
        </button>
      </div>
    </div>
  </div>
</div>
