<div class="navbar" id="navbar">
  <div class="center">
    <img
      style="cursor: pointer"
      routerLink=""
      src="/assets/images/logos/milanesas_new_logo.png"
      alt=""
    />
    <div class="right">
      <div class="nav-tabs">
        <div class="top">
          <p routerLink="" [class.active]="this.path === '/'">INICIO</p>
          <p routerLink="Menu" routerLinkActive="active">MENÚ</p>
          <p routerLink="Historia" routerLinkActive="active">HISTORIA</p>
          <p routerLink="Sucursales" routerLinkActive="active">SUCURSALES</p>
        </div>
        <div class="bottom">
          <p class="franchise-blood" routerLink="Franquicia" routerLinkActive="active" (click)="scrollToElement('franchises')">
            Franquicia
          </p>
          <p routerLinkActive="active" routerLink="/Bolsa-de-trabajo">
            Bolsa de trabajo
          </p>
          <p routerLink="" (click)="scrollToElement('landing')">Contacto</p>
        </div>
      </div>
      <button routerLink="/Franquicia">ADQUIERE UNA FRANQUICIA</button>
    </div>
    <i class="fa fa-bars" (click)="menu = !menu"></i>
  </div>
  <div class="menu" *ngIf="menu">
    <p routerLink="/" [class.active]="this.path === '/'" (click)="menu = !menu">
      INICIO
    </p>
    <p routerLink="Sucursales" routerLinkActive="active" (click)="menu = !menu">
      SUCURSALES
    </p>
    <p routerLink="Historia" routerLinkActive="active" (click)="menu = !menu">
      HISTORIA
    </p>
    <p routerLink="Menu" routerLinkActive="active" (click)="menu = !menu">
      MENÚ
    </p>
    <p
      routerLink="Bolsa-de-trabajo"
      routerLinkActive="active"
      (click)="menu = !menu"
    >
      BOLSA DE TRABAJO
    </p>
    <button routerLink="/Franquicia" (click)="menu = !menu">
      ADQUIERE UNA FRANQUICIA
    </button>
  </div>
</div>
