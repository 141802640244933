import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BranchesComponent } from './pages/branches/branches.component';
import { FranchisesComponent } from './pages/franchises/franchises.component';
import { HistoryComponent } from './pages/history/history.component';
import { JobComponent } from './pages/job/job.component';
import { LandingComponent } from './pages/landing/landing.component';
import { MenuComponent } from './pages/menu/menu.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PolicyComponent } from './pages/policy/policy.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'Menu',
    component: MenuComponent,
  },
  {
    path: 'Franquicia',
    component: FranchisesComponent,
  },
  {
    path: 'Historia',
    component: HistoryComponent,
  },
  {
    path: 'Sucursales',
    component: BranchesComponent,
  },
  {
    path: 'Bolsa-de-trabajo',
    component: ContactComponent,
  },
  {
    path: 'Aviso-de-privacidad',
    component: PolicyComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
