<div class="main-container">
  <swiper [config]="config">
    <ng-template swiperSlide>
      <div class="parent-container">
        <div class="parent-title-container">
          <h4>CRECE CON NOSOTROS</h4>
        </div>
      </div></ng-template
    >
    <ng-template swiperSlide>
      <div
        class="parent-container"
        style="
          background-image: url(/assets/images/placeholders/slide-contact2.png);
        "
      >
        <div class="parent-title-container">
          <h4>CRECE CON NOSOTROS</h4>
        </div>
      </div></ng-template
    >
    <ng-template swiperSlide>
      <div
        class="parent-container"
        style="
          background-image: url(/assets/images/placeholders/slide-contact3.png);
        "
      >
        <div class="parent-title-container">
          <h4>CRECE CON NOSOTROS</h4>
        </div>
      </div></ng-template
    >
    <ng-template swiperSlide>
      <div
        class="parent-container"
        style="
          background-image: url(/assets/images/placeholders/slide-contact4.png);
        "
      >
        <div class="parent-title-container">
          <h4>CRECE CON NOSOTROS</h4>
        </div>
      </div></ng-template
    >
  </swiper>
  <div class="body-container">
    <div class="body-container-content">
      <h4>ÚNETE A LA TRIPULACIÓN</h4>
      <div class="row" style="align-items: flex-end">
        <div class="input-container">
          <label for="">¿Quién eres tu?</label>
          <input
            class="name"
            type="text"
            placeholder="Nombre(s)"
            [(ngModel)]="contactForm.name"
          />
        </div>
        <input
          class="lastName"
          type="text"
          placeholder="Apellidos"
          [(ngModel)]="contactForm.lastName"
        />
        <input
          class="age"
          type="number"
          placeholder="Edad"
          [(ngModel)]="contactForm.age"
        />
      </div>
      <div class="row" style="gap: 30px">
        <input
          class="phoneNumber"
          type="text"
          placeholder="Celular"
          [(ngModel)]="contactForm.phoneNumber"
        />
        <div class="student-container">
          <p class="student-question">¿Estudiante?</p>
          <div class="checkbox">
            <input
              class="checkbox-field"
              type="radio"
              name="checkbox"
              id="Si"
              (click)="studentYes()"
            />
            <p>Si</p>
          </div>
          <div class="checkbox">
            <input
              class="checkbox-field"
              type="radio"
              name="checkbox"
              id="No"
              (click)="studentNo()"
            />
            <p>No</p>
          </div>
        </div>
      </div>
      <div class="student-info" *ngIf="showStudent">
        <div class="row">
          <div class="input-container">
            <label for="">¿En donde estudia?</label>
            <input
              class="student-info-container1"
              type="text"
              placeholder="Academia"
              [(ngModel)]="contactForm.school"
            />
          </div>
        </div>
        <div class="row" style="align-items: flex-end">
          <div class="input-container">
            <label for="">Grado de estudio</label>
            <input
              class="student-grade-container1"
              type="text"
              placeholder="Grado"
              [(ngModel)]="contactForm.grade"
            />
          </div>
          <div class="input-container">
            <label for="">¿Cual horario lleva?</label>
            <input
              class="time"
              type="time"
              [(ngModel)]="contactForm.timef"
              placeholder="aa"
            />
          </div>
          <input class="time" type="time" [(ngModel)]="contactForm.timel" />
        </div>
        <div class="row">
          <div class="input-container">
            <label for="">Comentarios</label>
            <input
              class="student-commentary-container1"
              type="text"
              placeholder="Comentarios"
              [(ngModel)]="contactForm.comment"
            />
          </div>
        </div>
      </div>

      <div class="row" style="align-items: flex-end">
        <div class="input-container">
          <label for="">Dirección</label>
          <input
            class="street1-container"
            type="text"
            placeholder="Calle 1"
            [(ngModel)]="contactForm.street1"
          />
        </div>
        <input
          class="street2-container"
          type="text"
          placeholder="Calle 2 (Opcional)"
          [(ngModel)]="contactForm.street2"
        />
        <input
          class="city-container"
          type="text"
          placeholder="Localidad"
          [(ngModel)]="contactForm.city"
        />
      </div>
      <div class="row" style="align-items: flex-end">
        <div class="input-container">
          <label for="Adjunta tu CV">Adjunta tu CV</label>
          <input 
            class="attachFiles" 
            name="Adjunta tu CV"
            type="file"
            placeholder="Adjunta tu CV"
            [(ngModel)]="contactForm.attachFiles" 
            accept=".pdf"
          />
        </div>
      </div>
      <div class="send-button">
        <button (click)="submit()" [disabled]="isLoading">ENVIAR</button>
      </div>
    </div>
  </div>
</div>
