import { Component, OnInit } from '@angular/core';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/environments/environment';
import SwiperCore, {
  SwiperOptions,
  Mousewheel,
  Scrollbar,
  Autoplay,
  EffectFade,
} from 'swiper';
import 'swiper/swiper.scss';
SwiperCore.use([Scrollbar, Mousewheel, Autoplay, EffectFade]);

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  showStudent: boolean = false;
  isLoading: boolean = false;
  contactForm: any = {
    origin: 'Contacto',
    name: '',
    lastName: '',
    age: '',
    phoneNumber: '',
    school: '',
    grade: '',
    timef: '',
    timel: '',
    comment: '',
    street1: '',
    street2: '',
    city: '',
    attachFiles: ''
  };
  config = {
    slidesPerView: 1,
    effect: 'fade',
    speed: 500,
    autoplay: {
      delay: 2000,
    },
  };
  constructor() {}

  ngOnInit(): void {}

  async submit() {
    this.contactForm.student = this.showStudent;
    if (
      this.contactForm.name !== '' &&
      this.contactForm.lastName !== '' &&
      this.contactForm.age !== '' &&
      this.contactForm.phoneNumber !== '' &&
      this.contactForm.street1 !== '' &&
      this.contactForm.city !== ''
    ) {
      if (this.contactForm.student) {
        if (
          this.contactForm.school !== '' &&
          this.contactForm.grade !== '' &&
          this.contactForm.comment !== '' &&
          this.contactForm.timef !== '' &&
          this.contactForm.timel !== ''
        ) {
          if (this.isLoading == false) {
            alert('¡Su correo fue enviado con exito!');
            const send = httpsCallable(functions, 'sendEmailContact');
            await send(this.contactForm).then((res) => {
              console.log(res.data);
              this.isLoading = true;
            });
          }
        } else {
          alert('Por favor complete los campos');
        }
      } else {
        alert('¡Su correo fue enviado con exito!');
        const send = httpsCallable(functions, 'sendEmailContact');
        await send(this.contactForm).then((res) => {
          console.log(res);
          this.isLoading = true;
        });
      }
    } else {
      alert('Por favor complete los campos');
    }
    this.contactForm = '';
  }
  studentYes() {
    this.showStudent = true;
  }
  studentNo() {
    this.showStudent = false;
  }
}
