<div class="main-container">
  <div class="plates-container">
    <div class="plate" *ngFor="let plate of menu[index]?.plates">
      <img src="{{ plate.img }}" alt="" />
      <h1>{{ plate.name }}</h1>
      <a href="https://api.whatsapp.com/send/?phone=5216622339073" target="_blank" class="text">
        Haz tu pedido
      </a>
    </div>
  </div>
</div>
