<div class="main-container">
  <h1>
    AVISO DE PRIVACIDAD SIMPLIFICADO <br />
    MILANESAS DEL PIRATA®
  </h1>
  <div class="content">
    <p>
      En cumplimiento a lo dispuesto por la Ley Federal de Protección de Datos
      Personales en Posesión de los Particulares y su Reglamento,
      <span>MILANESAS DEL PIRATA®</span> declara que es y será responsable del
      uso y protección de los datos personales que le proporcione con motivo de
      su actividad comercial, información que se manejará con absoluta
      confidencialidad y secrecía.
    </p>
    <p>
      <span>MILANESAS DEL PIRATA®</span> le informa el derecho que le asiste
      permanente para acceder, rectificar, cancelar u oponerse (ARCO) al
      tratamiento de sus datos personales, así como revocar el consentimiento
      que haya otorgado. En caso de ser de su interés ejercer sus derechos ARCO
      podrá hacerlo de forma remota enviando un correo electrónico a la cuenta
      <span style="
        color: #00a3ff;
        text-decoration: underline;
        font-weight: 100;
      ">contactoARCO@milanesasdelpirata.com.mx</span
      >
      , donde se le brindara la atención correspondiente.
    </p>
    <p>
      Así mismo se hace de su conocimiento que
      <span>MILANESAS DEL PIRATA®</span> únicamente utilizará sus datos
      personales que proporcione para:
    </p>
    <ul>
      <li>
        Mantener comunicación con clientes, socios comerciales o cualquier
        interesado en los productos que se comercializan.
      </li>
      <li>
        Generar comunicación mercadológica de los eventos y promociones de la
        marca
      </li>
      <li>Envíos de publicidad, promociones y ofertas.</li>
      <li>
        Conocer y atender las solicitudes de preguntas, quejas o comentarios que
        le formulen sus clientes.
      </li>
    </ul>
    <p>
      De momento a momento subsiste su derecho para oponerse o revocar el
      consentimiento que haya dado para el uso de sus datos personales. En caso
      de no ejercer dicha oposición se entenderá que ha otorgado su
      consentimiento tácitamente para el tratamiento de sus datos personales en
      los términos previstos en el presente aviso.
    </p>
    <p>
      En el momento en que así lo requiera y de forma permanente, usted podrá
      revisar el aviso de privacidad completo consultando la siguiente dirección
      electrónica https://avisodeprivacidadintegral/milanesasdelpirata
    </p>
  </div>
</div>
