import { Component, OnInit } from '@angular/core';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/environments/environment';
import SwiperCore, { SwiperOptions, Mousewheel, Scrollbar } from 'swiper';
import 'swiper/swiper.scss';
declare let videojs: any;
SwiperCore.use([Scrollbar, Mousewheel]);

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  isLoading: boolean = false;
  player: any;
  activeButton: boolean = false;
  agreementYes() {
    this.activeButton = !this.activeButton;
  }
  landingForm: any = {
    origin: '',
    name: '',
    lastName: '',
    age: '',
    email: '',
    phoneNumber: '',
    about: '',
    comment: '',
  };
  locations = [
    {
      name: 'Milanesas del Pirata',
      location:
        'Blvd. Antonio Quiroga #74, Plaza Conquistador, 83224 Hermosillo, Son.',
      selected: true,
      lat: 29.09154687048637,
      long: -111.02259169999999,
    },
    {
      name: 'Milanesas del Pirata',
      location:
        'Anillo Vial Fray Junípero Serra #21260, CP: 76223, Santiago de Querétaro, Querétaro',
      selected: false,
      lat: 20.676588140776254,
      long: -100.43144691534222,
    },
  ];
  selected_location: any = {};
  lat = 0;
  long = 0;

  promos = [
    {
      img: '/assets/images/promos/info.png',
      title: 'SUC. Lorem Ipsum  B',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non fames pellentesque tellus pretium.',
    },
    {
      img: '/assets/images/promos/uber.png',
      title: 'SUC. Lorem Ipsum  B',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non fames pellentesque tellus pretium.',
    },
    // {
    //   img: '/assets/images/promos/first.png',
    //   title: 'SUC. Lorem Ipsum  B',
    //   description:
    //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non fames pellentesque tellus pretium.',
    // },
  ];
  slicePromos = this.promos.slice(0, 2);

  constructor() {}

  ngOnInit(): void {
    this.doVideoStuff();
    this.selected_location = this.locations[0];
    this.lat = this.locations[0].lat;
    this.long = this.locations[0].long;
  }
  ngOnDestroy() {
    if (!this.player) return;
    this.player.dispose();
  }

  async doVideoStuff() {
    //@ts-ignore
    this.player = window.player = videojs('video');
    this.player.src({
      src: 'https://videoacademia.inowu.dev/media/volume/Ignorar/m/master.m3u8',
      type: 'application/x-mpegURL',
    });

    this.player.on('loadedmetadata', () => {
      this.player.play();
    });
  }

  toggleLocation(index) {
    for (let location of this.locations) {
      if (location.selected) location.selected = false;
    }
    this.locations[index].selected = true;
    this.selected_location = this.locations[index];
    this.lat = this.locations[index].lat;
    this.long = this.locations[index].long;
  }

  showMore() {
    this.slicePromos = this.promos;
  }

  async submit() {
    this.landingForm.button = this.activeButton;
    this.landingForm.origin == '' || undefined ? this.landingForm.origin = 'Landing' : '';
    if (
      this.landingForm.name !== '' &&
      this.landingForm.lastName !== '' &&
      this.landingForm.age !== '' &&
      this.landingForm.email !== '' &&
      this.landingForm.phoneNumber !== '' &&
      this.landingForm.about !== '' &&
      this.landingForm.comment !== ''
    ) {
      if (this.isLoading == false) {
        alert('¡Su correo fue enviado con exito!')
        const send = httpsCallable(functions, 'sendEmailContact');
        await send(this.landingForm).then((res) => {
          console.log(res.data);
          this.isLoading = true;
        });
      }
    } else {
      alert('¡Por favor complete los campos!');
    }
    this.landingForm = '';
  }
}
