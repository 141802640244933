import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  index = 0;
  menu = [
    {
      name: 'SUPERCHEESE',
      plates: [
        {
          name: 'Cielo, Mar y Tierra',
          img: '../../../assets/images/menu/plate_18.jpeg',
        },
        {
          name: 'Mar y tierra',
          img: '../../../assets/images/menu/plate_19.jpeg',
        },
        {
          name: 'Boneless BBQ',
          img: '../../../assets/images/menu/plate_1.jpeg',
        },
        {
          name: 'Toritos',
          img: '../../../assets/images/menu/plate_2.jpeg',
        },
        {
          name: 'Camaron Pirata',
          img: '../../../assets/images/menu/plate_3.jpeg',
        },
        {
          name: 'Completa de pollo',
          img: '../../../assets/images/menu/plate_4.jpeg',
        },
        {
          name: 'Aros de Cebolla',
          img: '../../../assets/images/menu/plate_5.jpeg',
        },
        {
          name: 'Media de Res',
          img: '../../../assets/images/menu/plate_6.jpeg',
        },
        {
          name: 'Completa de Res',
          img: '../../../assets/images/menu/plate_7.jpeg',
        },
        {
          name: 'Boneless Buffalo',
          img: '../../../assets/images/menu/plate_8.jpeg',
        },
        {
          name: 'Hamburguesa BBQ',
          img: '../../../assets/images/menu/plate_9.jpeg',
        },
        {
          name: 'Tiras de Pollo',
          img: '../../../assets/images/menu/plate_10.jpeg',
        },
        {
          name: 'Milanesa pollo crunch',
          img: '../../../assets/images/menu/plate_12.jpeg',
        },
        // {
        //   name: 'Media de Res',
        //   img: '../../../assets/images/menu/plate_13.jpeg',
        // },
        {
          name: 'Charola',
          img: '../../../assets/images/menu/plate_14.jpeg',
        },
        {
          name: 'Camarones',
          img: '../../../assets/images/menu/plate_16.jpeg',
        },
        {
          name: 'Media de Pollo',
          img: '../../../assets/images/menu/plate_17.jpeg',
        },
        {
          name: 'Papas fritas',
          img: '../../../assets/images/menu/plate_20.jpeg',
        },
        {
          name: 'Papas piratas',
          img: '../../../assets/images/menu/plate_21.jpeg',
        },
        {
          name: 'Papas con queso',
          img: '../../../assets/images/menu/plate_22.jpeg',
        },
      ],
    },
    {
      name: 'PIZZAs',
      plates: ['../../../assets/images/plates/first-food.png'],
    },
  ];
  constructor() {}

  ngOnInit(): void {
    console.log(this.index);
  }
}
